import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
<Layout location={"/about/"} title={"About Raid Boss"}>
    <SEO
      title="About Raid Boss - Tier list, types and counters for PoGO"
      description="Raid boss battles are great moments to share in Pokémon GO. To help you beat them, we made an app called Raid Boss. Discover how it can help you!"
      slug={"/about/"}
    />
    <h1>Raid Boss - Tier list, types and counters for Pokémon GO</h1>
    <p>
      Raid Boss - Tier list, types and counters for PoGo is an application to
      help you defeat the raid bosses by checking the best counters, weaknesses
      and stats of a Pokemon.
    </p>
    <p>
      A Raid Boss is a Pokémon that takes over a Gym for 2 hours. During this
      time your gym is not accessible for fighting and you can't feed berries to
      your Pokemon defending the gym. The raid boss is a lot stronger than its
      normal version you can encounter in the wild. You'll need help from other
      trainers to defeat this stronger pokemon in order to win a few prices
      (such as TM, berries and so on) and to have a chance of catching it.{" "}
    </p>
    <p>
      Depending on your level, you'll be able to defeat a Raid Boss up to 2-3
      stars (representing the difficulty, the maximum stars, for legendaries,
      being 5). It's recommended to choose the right Pokemons and fight in teams
      with other fellow trainers! This app helps you for the first part, select
      the right mons to counter the raid boss.{" "}
    </p>
    <p>
      Discover all the Pokemon GO raid bosses, their weaknesses and recommended
      counters in order to defeat them!
    </p>
    <p>
      Be able to defeat and capture normal, rare and legendary raid bosses when
      they attack your local gyms!{" "}
    </p>
    <p>
      Get the app, check the boss weakness and some of its best counters, pick
      your best Pokemons and win the fight to gain many rewards and the chance
      of capturing the raid's boss.{" "}
    </p>
    <p>
      Elegant - flat design - app containing a curated list of all the current
      raidbosses and their related informations.{" "}
    </p>
    <p>
      The application will be updated when the official game receives an update
      and new raid bosses are implemented, don't be surprised to see the newest
      exclusive legendaries in the list, it just means they are coming soon in
      the next release of the game!{" "}
    </p>
    <p>
      Use the searchbar to get the information you want about a raid boss
      faster!{" "}
    </p>
    <p>
      Get all the data you need about moves: their consumption, energy
      generation, damages and type. Compare the charge and quick moves.{" "}
    </p>
    <p>
      You can find in this guide the best quick and charged moves for each
      counters. It doesn't mean you have to have pokemons with those moves, they
      simply are the best moves for the counter pkmn.{" "}
    </p>
    <p>Win all your raids with the help of "Raid Boss". </p>
    <p>
      Some of the legendaries in this list are not implemented in game yet, or
      not available anymore. They are still in the app as a reference, for you
      to be prepared for the next update of the game. Click on any pokemon to
      see its details in order to win your raid battle!
    </p>
    <h5>DISCLAIMER</h5>
    <ul>
      <li>
        I'm open to suggestions, I have implemented many features because of a
        feedback in the comments. Please feel free to give positive and negative
        feedbacks. I just wanted to make this app for myself and thought it
        could help other fellow PoGo players.
      </li>
      <li>
        I typed all the data by hand so if you see an absurdity such as a Ground
        type attack for Alakazam, it must be that I made a typo, it will most
        likely be corrected in the next update!
      </li>
      <li>
        The images in the app icon and some images in the app itself are made
        https://www.flaticon.com/authors/nikita-golubev from www.flaticon.com
      </li>
      <li>
        Pokémon images by The Artificial (http://theartificial.nl/pokemonicons/)
      </li>
      <li>
        This app is an unofficial Pokemon Go guide only, it is not authorised
        nor created by the creator of the game. This application complies with
        US Copyright law guidelines of "fair use". If you feel there is a direct
        copyright or trademark violation that doesn't follow within the "fair
        use" guidelines, please contact me directly.
      </li>
    </ul>
  </Layout>
)
